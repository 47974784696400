<template>
  <section
    style="padding-left: 25px; margin-left: 20px; padding-right: 10px; margin-right: 10px;"
  >
    <v-card class="mb-6 ml-6 mt-6 mr-2">
      <v-card-title>Liste des Reclamations</v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
      <v-data-table
        :search="search"
        :loading="isLoadingTables"
        loading-text="Loading..."
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 30],
        }"
        :headers="headers"
        :items="listComplaint"
        class="elevation-1"
        item-key="id"
      >
        <template v-slot:item.solve="{ item }">
          <v-chip v-if="item.solve" variant="outlined">
            <span style="color: #407001;">Résolu</span>
          </v-chip>

          <v-chip variant="outlined" v-else>
            <span style="color: red;">Non résolu</span>
          </v-chip>
        </template>
        <template v-slot:item.modifier="{ item }">
          <v-icon @click="toggleSolveStatus(item)">
            {{ icons.mdiPencil }}
          </v-icon>
        </template>

        <template v-slot:item.supprimer="{ item }">
          <v-icon @click="RedirectVersVerset(item)">
            {{ icons.mdiFileEyeOutline }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog v-model="dialogConfirmation" max-width="600">
        <v-card>
          <v-card-title class="text-h6 align-center">
            Êtes-vous sûr de changer l'etat du réclamation?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="reinitialisation()"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="ConfirmeSolveStatus()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>

<script>
import axios from "axios";
import Constant from "@/utils/constants";

export default {
  data() {
    return {
      dialogConfirmation: false,
      icons: {
        mdiFileEyeOutline: "mdi-file-eye-outline",
        mdiPencil: "mdi-pencil",
      },
      search: "",
      isLoadingTables: false,
      listComplaint: [],
      Complaint: null,
      headers: [
        { text: "Réclamation", value: "reclamation" },
        { text: "Surat", value: "surahDetails.frenchName" },
        {
          text: "Numéro verset",
          value: "versetDetails.verse_number",
          align: "center",
        },
        { text: "Etat", value: "solve" },
        { text: "Modifier Réclamation", value: "modifier", align: "center" },
        { text: "Vers Verset", value: "supprimer", align: "center" },
      ],
    };
  },
  methods: {
    async reinitialisation() {
      this.Complaint = null;
      this.dialogConfirmation = false;
    },
    async toggleSolveStatus(Complaint) {
      this.Complaint = Complaint;
      this.dialogConfirmation = true;
    },
    async ConfirmeSolveStatus() {
      this.Complaint.solve = !this.Complaint.solve;
      try {
        const response = await axios.post(
          Constant.QORANI + `reclamation/edit/${this.Complaint.id}`,
          {
            solve: this.Complaint.solve,
            reclamation: this.Complaint.reclamation,
            verset_Id: this.Complaint.verset_Id,
          }
        );
        this.reinitialisation();
        window.location.reload();
      } catch (error) {
        console.error("Error toggling solve status:", error);
      }
    },

    async RedirectVersVerset(iteam) {
      this.$router.push({
        name: "EditVerset",
        params: {
          versetId: iteam.verset_Id,
          edit: false,
          complaint: true,
          detailComplaint: iteam,
        },
      });
    },

    async getAllComplaintWithSurahAndVerset() {
      try {
        const response = await axios.get(
          Constant.QORANI + "reclamation/getall"
        );
        const complaints = response.data;
        const surahAndVersetPromises = complaints.map(async (complaint) => {
          try {
            const surahResponse = await axios.get(
              Constant.QORANI + `surat/get/${complaint.surat_Id}`
            );
            const versetResponse = await axios.get(
              Constant.QORANI + `verset/get/${complaint.verset_Id}`
            );
            return { surah: surahResponse.data, verset: versetResponse.data };
          } catch (error) {
            console.error(
              `Error fetching details for complaint ID ${complaint.id}:`,
              error
            );
            return null;
          }
        });

        const surahAndVersetDetailsArray = await Promise.all(
          surahAndVersetPromises
        );

        complaints.forEach((complaint, index) => {
          const details = surahAndVersetDetailsArray[index];
          if (details) {
            complaint.surahDetails = details.surah;
            complaint.versetDetails = details.verset;
          }
        });

        return complaints;
      } catch (error) {
        console.error("Error fetching complaints:", error);
        throw error;
      }
    },
  },
  async created() {
    this.isLoadingTables = true;
    this.listComplaint = await this.getAllComplaintWithSurahAndVerset();
    this.isLoadingTables = false;
  },
};
</script>
