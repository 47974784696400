var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticStyle:{"padding-left":"25px","margin-left":"20px","padding-right":"10px","margin-right":"10px"}},[_c('v-card',{staticClass:"mb-6 ml-6 mt-6 mr-2"},[_c('v-card-title',[_vm._v("Liste des Reclamations")]),_c('v-text-field',{staticStyle:{"padding":"15px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":"","search":_vm.search},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"loading":_vm.isLoadingTables,"loading-text":"Loading...","footer-props":{
        'items-per-page-options': [5, 10, 15, 30],
      },"headers":_vm.headers,"items":_vm.listComplaint,"item-key":"id"},scopedSlots:_vm._u([{key:"item.solve",fn:function(ref){
      var item = ref.item;
return [(item.solve)?_c('v-chip',{attrs:{"variant":"outlined"}},[_c('span',{staticStyle:{"color":"#407001"}},[_vm._v("Résolu")])]):_c('v-chip',{attrs:{"variant":"outlined"}},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("Non résolu")])])]}},{key:"item.modifier",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.toggleSolveStatus(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"item.supprimer",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.RedirectVersVerset(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileEyeOutline)+" ")])]}}])})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialogConfirmation),callback:function ($$v) {_vm.dialogConfirmation=$$v},expression:"dialogConfirmation"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 align-center"},[_vm._v(" Êtes-vous sûr de changer l'etat du réclamation? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1 error","text":""},on:{"click":function($event){return _vm.reinitialisation()}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"info darken-1 ","text":""},on:{"click":function($event){return _vm.ConfirmeSolveStatus()}}},[_vm._v(" Confirmer ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }